@import url('https://fonts.googleapis.com/css?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body, html {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  /* Set height to 100% of viewport */
  
  background-size: cover; /* Cover entire viewport */
  background-repeat: no-repeat;
}

.ant-menu-item-selected {
  background-color: #e6f7ff; /* Replace with your desired background color */
}
.Link{
   color: #e6f7ff;
}
input:focus,textarea:focus,select:focus{
  outline:0 !important;
  box-shadow:none !important;
}
.iconed{
  border-radius: 50%;
}