.main{
    /* background-image: url("https://res-console.cloudinary.com/dbzebdg6r/thumbnails/v1/image/upload/v1718688529/Z3JlX3poMG9tOA==/drilldown"); */
    background-image: url("https://img.freepik.com/free-vector/blue-bluured-background_1107-164.jpg?w=740&t=st=1719025923~exp=1719026523~hmac=df1977579e8232ed2b2c6233638cbc925456c9816abcc0b33a0a2b2ab6a4e5e9");
    background-repeat: no-repeat;
    height: 100;
    min-height: 200%;
    margin: 0; /* Remove default margin */
    padding-bottom: 2px;
    background-size: cover; /* Cover the entire container */
    background-position: center;
}
.dashboard{
    background-image: url("https://img.freepik.com/free-vector/blurred-abstract-background-design_1107-169.jpg?w=740&t=st=1718962925~exp=1718963525~hmac=2a17f2628bdd08d1c471fdc4b57cb77a605958ea604fd6bcea3251267d5ff113");
    background-repeat: no-repeat;
    height: 100;
    min-height: 200%;
    margin: 0; /* Remove default margin */
    padding-bottom: 2px;
    background-size: cover; /* Cover the entire container */
    background-position: center;
}
.single{
    background-image: url("https://img.freepik.com/free-vector/abstract-background-design_1107-155.jpg?w=740&t=st=1718962454~exp=1718963054~hmac=5f14c401273f074951e1ba37698f3310f8d592199f54965b2525ecb6e9dd0357");
    background-repeat: no-repeat;
    height: 100;
    min-height: 200%;
    margin: 0; /* Remove default margin */
    padding-bottom: 2px;
    background-size: cover; /* Cover the entire container */
    background-position: center;
}

.vid{
    max-height: 400px;
}
